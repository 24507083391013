<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportCustomerView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Cliente",
        title: "Cliente",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/CustomerOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "supplierCustomerName",
            title: "Cli..For",
            type: "text",
            selected: false,
            requiredOData: true,
          },
          {
            field: "typePersonName",
            title: "Tipo",
            type: "text",
            selected: true,
            requiredOData: true,
          },
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "text",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "document",
            title: "CNPJ/CPF",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "stateInscription",
            title: "IE/RG",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "municipalInscription",
            title: "IM",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "customerCategoryName",
            title: "Categoria",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "originCustomerName",
            title: "Origem",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "statusName",
            title: "Status",
            type: "text",
            classCssTitle: "text-center",
            fieldComparison: "statusName",
            requiredOData: true,
            selected: true,
            classCssBody: "text-center",
          },
          /*   {
               field: "creditLimit",
               title: "L.Crédito",
               type: "currency",
               classCssBody: "primary",
               selected: false,
               requiredOData: false,
             },
             {
               field: "patrimonyLimit",
               title: "L.Patrimônio",
               type: "currency",
               classCssBody: "primary",
               selected: false,
               requiredOData: false,
             },*/
          {
            field: "phone",
            title: "Telefone",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "webSite",
            title: "Site",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "email",
            title: "E-mail",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "observation",
            title: "Observações",
            type: "text",
            selected: false,
            requiredOData: false,
          },
        ],
        rules: [
          {
            name: "isCustumer",
            title: "Cliente?",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Sim", value: true },
              { title: "Não", value: false },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "isSupplier",
            title: "Fornecedor?",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Sim", value: true },
              { title: "Não", value: false },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "typePerson",
            title: "Tipo",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Física", value: 1 },
              { title: "Jurídica", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "companyName",
            title: "Razão Social/Nome",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "tradeName",
            title: "Fantasia/Apelido",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "document",
            title: "CNPJ/CPF",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "stateInscription",
            title: "IE/RG",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "municipalInscription",
            title: "IM",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "categoryCustomerId",
            title: "Categoria",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/category-customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "originCustomerId",
            title: "Origem",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/origin-customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "status",
            title: "Status",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Ativo", value: 1 },
              { title: "Inativo", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          /*  {
              name: "creditLimit",
              title: "L.Crédito",
              icon: "fas fa-database",
              typeField: "numeric",
              type: "field",
              notDuplicate: false,
              isRequired: false,
            },
            {
              name: "patrimonyLimit",
              title: "L.Patrimônio",
              icon: "fas fa-database",
              typeField: "numeric",
              type: "field",
              notDuplicate: false,
              isRequired: false,
            },
            */
          {
            name: "phone",
            title: "Telefone",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "webSite",
            title: "Site",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "email",
            title: "E-mail",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "observation",
            title: "Observações",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
